// src/components/Footer.js
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-links">
        <a href="https://saltathletic.com" target="_blank" rel="noopener noreferrer">
          Back to SaltAthletic.com
        </a>
        <a href="https://instagram.com/salt.athletic" target="_blank" rel="noopener noreferrer">
          Instagram
        </a>
      </div>
      <p>&copy; {new Date().getFullYear()} Salt Athletic</p>
    </footer>
  );
}

export default Footer;
