// src/components/Contact.js
import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <p>If you have any questions, feel free to reach out to us at:</p>
      <p>Email: mail@saltathletic.com</p>
    </div>
  );
}

export default Contact;
