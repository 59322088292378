// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo1 from "../assets/Lockup Horizontal — Black.png"
import './Header.css';

function Header() {
  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo1} alt="SALT Athletic Logo" className="logo" />
      </div>
      <nav className="navigation">
        <Link to="/Aercase-Wholesale">Home</Link>
        <Link to="/contact">Contact Us</Link>
      </nav>
    </header>
  );
}

export default Header;