// src/components/Form.js
import React, { useState } from 'react';
import './Form.css';
import sizingImage from '../assets/Sizing_Guide.JPG'; // Replace with your actual image path
import emailjs from 'emailjs-com';

function Form() {
  const [formData, setFormData] = useState({
    // Personal Information
    fullName: '',
    phoneNumber: '',
    email: '',
    address: '',
    // Product Quantities
    aerCaseRegular: 0,
    aerCaseXL: 0,
    // Discount
    discount: 0,
  });

  const [submissionMessage, setSubmissionMessage] = useState('');

  const UNIT_PRICES = {
    aerCaseRegular: 59,
    aerCaseXL: 59,
  };

  // Handle text input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle quantity changes
  const handleQuantityChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = Number(value);
    // Ensure the value stays within min and max
    if (parsedValue < 0) parsedValue = 0;
    if (parsedValue > 100000) parsedValue = 100000; // Adjusted max value

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: parsedValue };
      const totalUnits = calculateTotalUnits(updatedData);
      const discount = calculateDiscount(totalUnits);
      return { ...updatedData, discount };
    });
  };

  // Calculate total units
  const calculateTotalUnits = (data) => {
    return Number(data.aerCaseRegular) + Number(data.aerCaseXL);
  };

  // Updated discount calculation
  const calculateDiscount = (totalUnits) => {
    if (totalUnits >= 100) return 50;
    if (totalUnits >= 75) return 45;
    if (totalUnits >= 50) return 40;
    if (totalUnits >= 30) return 35;
    if (totalUnits >= 20) return 30;
    if (totalUnits >= 10) return 25;
    if (totalUnits >= 5) return 20;
    return 0;
  };

  // Calculate total price before and after discount
  const calculateSubtotal = (data) => {
    return (
      data.aerCaseRegular * UNIT_PRICES.aerCaseRegular +
      data.aerCaseXL * UNIT_PRICES.aerCaseXL
    );
  };

  const calculateTotalPrice = (data) => {
    const subtotal = calculateSubtotal(data);
    const discountAmount = (subtotal * data.discount) / 100;
    return subtotal - discountAmount;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const orderSummary = {
      // Personal Information
      fullName: formData.fullName,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      address: formData.address,
      // Order Details
      aerCaseRegular: formData.aerCaseRegular,
      aerCaseXL: formData.aerCaseXL,
      totalUnits: calculateTotalUnits(formData),
      discount: formData.discount,
      subtotal: calculateSubtotal(formData),
      totalPrice: calculateTotalPrice(formData),
    };

    // Send email using EmailJS
    const templateParams = {
      to_email: 'mail@saltathletic.com',
      from_name: formData.fullName,
      from_email: formData.email,
      phone_number: formData.phoneNumber,
      address: formData.address,
      aerCaseRegular: formData.aerCaseRegular,
      aerCaseXL: formData.aerCaseXL,
      totalUnits: orderSummary.totalUnits,
      discount: orderSummary.discount,
      subtotal: orderSummary.subtotal.toLocaleString(),
      totalPrice: orderSummary.totalPrice.toLocaleString(),
    };

    emailjs
      .send(
        'service_nt97msm', // Replace with your EmailJS service ID
        'template_6oyxpzb', // Replace with your EmailJS template ID
        templateParams,
        'oFw1i3jHUKLc7icpS' // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setSubmissionMessage(
            'You will receive an email with your invoice within 2 business day.'
          );
          // Reset form
          setFormData({
            fullName: '',
            phoneNumber: '',
            email: '',
            address: '',
            aerCaseRegular: 0,
            aerCaseXL: 0,
            discount: 0,
          });
        },
        (err) => {
          console.error('FAILED...', err);
          setSubmissionMessage(
            'There was an error submitting your order. Please try again.'
          );
        }
      );
  };

  return (
    <form onSubmit={handleSubmit} className="order-form">
      <h2>Order Form</h2>

      {/* Personal Information Section */}
      <div className="personal-info">
        <label>
          Full Name:
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            required
          />
        </label>

        <label>
          Phone Number:
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            required
          />
        </label>

        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </label>

        <label>
          Address:
          <textarea
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            required
          />
        </label>
      </div>

      {/* Product Quantities and Sizing Image */}
      <div className="product-section">
        {/* Product Quantities */}
        <div className="product-quantity">
          <h3>Products</h3>

          {/* Aērcase Regular */}
          <div className="product-item">
            <label htmlFor="aerCaseRegular">Aērcase Regular:</label>
            <input
              type="number"
              name="aerCaseRegular"
              min="0"
              max="100000"
              step="1"
              value={formData.aerCaseRegular}
              onChange={handleQuantityChange}
              className="number-input"
            />
          </div>

          {/* Aērcase XL */}
          <div className="product-item">
            <label htmlFor="aerCaseXL">Aērcase XL:</label>
            <input
              type="number"
              name="aerCaseXL"
              min="0"
              max="100000"
              step="1"
              value={formData.aerCaseXL}
              onChange={handleQuantityChange}
              className="number-input"
            />
          </div>

          {/* Discount Display */}
          <div className="discount-display">
            <p>
              Total Units: {calculateTotalUnits(formData)} <br />
              Subtotal: ${calculateSubtotal(formData).toLocaleString()} <br />
              Discount: {formData.discount}% <br />
              Total Price: ${calculateTotalPrice(formData).toLocaleString()}
            </p>
          </div>
        </div>

        {/* Sizing Image */}
        <div className="sizing-image-container">
          <a href={sizingImage} target="_blank" rel="noopener noreferrer">
            <img
              src={sizingImage}
              alt="Sizing Guide"
              className="sizing-image"
            />
          </a>
        </div>
      </div>

      {/* Submit Button */}
      <button type="submit" disabled={calculateTotalUnits(formData) < 5}>
        Submit Order
      </button>

      {/* Submission Message */}
      {submissionMessage && (
        <div className="submission-message">
          <p>{submissionMessage}</p>
        </div>
      )}
    </form>
  );
}

export default Form;
