// src/components/Intro.js
import React from 'react';
import image1 from '../assets/salt-aercase.jpg';
import './Intro.css';

function Intro() {
  return (
    <section className="intro">
      <h1>Welcome to Salt Athletic's Aērcase order form</h1>
      <p>Your premium source for fresh sports bags.</p>
      <div className="images">
       <a href="https://saltathletic.com/products/aercase-cleat-bag">
        <img src={image1} alt="Aērcase Cleat Bag" />
        </a>
      </div>
      <p>
        Send us an email if would like to add your logo on the handle. (MOQ 300)
      </p>
    </section>
  );
}

export default Intro;
